/* tslint:disable */
/* eslint-disable */
/**
 * Etana Digital API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface DatabaseWallet
 */
export interface DatabaseWallet {
    /**
     * 
     * @type {{ [key: string]: OpenapiAccount; }}
     * @memberof DatabaseWallet
     */
    'accounts'?: { [key: string]: OpenapiAccount; };
    /**
     * 
     * @type {boolean}
     * @memberof DatabaseWallet
     */
    'active'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof DatabaseWallet
     */
    'managers': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DatabaseWallet
     */
    'orgId': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseWallet
     */
    'owner': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseWallet
     */
    'walletId': string;
}
/**
 * 
 * @export
 * @interface OpenapiAccount
 */
export interface OpenapiAccount {
    /**
     * 
     * @type {boolean}
     * @memberof OpenapiAccount
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OpenapiAccount
     */
    'address'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OpenapiAccount
     */
    'assets'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof OpenapiAccount
     */
    'token'?: string;
}
/**
 * 
 * @export
 * @interface OpenapiOpenapiWatchtowerCoreBalanceResponse
 */
export interface OpenapiOpenapiWatchtowerCoreBalanceResponse {
    /**
     * 
     * @type {string}
     * @memberof OpenapiOpenapiWatchtowerCoreBalanceResponse
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof OpenapiOpenapiWatchtowerCoreBalanceResponse
     */
    'asset'?: string;
    /**
     * 
     * @type {string}
     * @memberof OpenapiOpenapiWatchtowerCoreBalanceResponse
     */
    'balance'?: string;
    /**
     * 
     * @type {number}
     * @memberof OpenapiOpenapiWatchtowerCoreBalanceResponse
     */
    'decimals'?: number;
    /**
     * 
     * @type {string}
     * @memberof OpenapiOpenapiWatchtowerCoreBalanceResponse
     */
    'network'?: string;
}
/**
 * 
 * @export
 * @interface OpenapiRoutesBalanceOnWalletResponse
 */
export interface OpenapiRoutesBalanceOnWalletResponse {
    /**
     * 
     * @type {Array<OpenapiOpenapiWatchtowerCoreBalanceResponse>}
     * @memberof OpenapiRoutesBalanceOnWalletResponse
     */
    'data'?: Array<OpenapiOpenapiWatchtowerCoreBalanceResponse>;
    /**
     * 
     * @type {string}
     * @memberof OpenapiRoutesBalanceOnWalletResponse
     */
    'ts'?: string;
    /**
     * 
     * @type {string}
     * @memberof OpenapiRoutesBalanceOnWalletResponse
     */
    'walletId'?: string;
}
/**
 * 
 * @export
 * @interface RequestAddAssetRequest
 */
export interface RequestAddAssetRequest {
    /**
     * 
     * @type {Array<RequestAssetData>}
     * @memberof RequestAddAssetRequest
     */
    'assets': Array<RequestAssetData>;
    /**
     * 
     * @type {string}
     * @memberof RequestAddAssetRequest
     */
    'walletId': string;
}
/**
 * 
 * @export
 * @interface RequestAssetData
 */
export interface RequestAssetData {
    /**
     * 
     * @type {string}
     * @memberof RequestAssetData
     */
    'asset': string;
    /**
     * 
     * @type {string}
     * @memberof RequestAssetData
     */
    'network': string;
}
/**
 * 
 * @export
 * @interface RequestHideAssetRequest
 */
export interface RequestHideAssetRequest {
    /**
     * 
     * @type {string}
     * @memberof RequestHideAssetRequest
     */
    'asset': string;
    /**
     * 
     * @type {string}
     * @memberof RequestHideAssetRequest
     */
    'network': string;
    /**
     * 
     * @type {string}
     * @memberof RequestHideAssetRequest
     */
    'walletId': string;
}

/**
 * AssetApi - axios parameter creator
 * @export
 */
export const AssetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Add an asset to a wallet
         * @summary Add Asset
         * @param {RequestAddAssetRequest} req add asset request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DashboardWalletsAssetsAddPost: async (req: RequestAddAssetRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'req' is not null or undefined
            assertParamExists('v1DashboardWalletsAssetsAddPost', 'req', req)
            const localVarPath = `/v1/dashboard/wallets/assets/add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Hide an asset from a wallet
         * @summary Hide Asset
         * @param {RequestHideAssetRequest} req hide asset request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DashboardWalletsAssetsHidePost: async (req: RequestHideAssetRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'req' is not null or undefined
            assertParamExists('v1DashboardWalletsAssetsHidePost', 'req', req)
            const localVarPath = `/v1/dashboard/wallets/assets/hide`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssetApi - functional programming interface
 * @export
 */
export const AssetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssetApiAxiosParamCreator(configuration)
    return {
        /**
         * Add an asset to a wallet
         * @summary Add Asset
         * @param {RequestAddAssetRequest} req add asset request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DashboardWalletsAssetsAddPost(req: RequestAddAssetRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatabaseWallet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1DashboardWalletsAssetsAddPost(req, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetApi.v1DashboardWalletsAssetsAddPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Hide an asset from a wallet
         * @summary Hide Asset
         * @param {RequestHideAssetRequest} req hide asset request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DashboardWalletsAssetsHidePost(req: RequestHideAssetRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatabaseWallet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1DashboardWalletsAssetsHidePost(req, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetApi.v1DashboardWalletsAssetsHidePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AssetApi - factory interface
 * @export
 */
export const AssetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssetApiFp(configuration)
    return {
        /**
         * Add an asset to a wallet
         * @summary Add Asset
         * @param {RequestAddAssetRequest} req add asset request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DashboardWalletsAssetsAddPost(req: RequestAddAssetRequest, options?: RawAxiosRequestConfig): AxiosPromise<DatabaseWallet> {
            return localVarFp.v1DashboardWalletsAssetsAddPost(req, options).then((request) => request(axios, basePath));
        },
        /**
         * Hide an asset from a wallet
         * @summary Hide Asset
         * @param {RequestHideAssetRequest} req hide asset request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DashboardWalletsAssetsHidePost(req: RequestHideAssetRequest, options?: RawAxiosRequestConfig): AxiosPromise<DatabaseWallet> {
            return localVarFp.v1DashboardWalletsAssetsHidePost(req, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AssetApi - object-oriented interface
 * @export
 * @class AssetApi
 * @extends {BaseAPI}
 */
export class AssetApi extends BaseAPI {
    /**
     * Add an asset to a wallet
     * @summary Add Asset
     * @param {RequestAddAssetRequest} req add asset request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public v1DashboardWalletsAssetsAddPost(req: RequestAddAssetRequest, options?: RawAxiosRequestConfig) {
        return AssetApiFp(this.configuration).v1DashboardWalletsAssetsAddPost(req, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Hide an asset from a wallet
     * @summary Hide Asset
     * @param {RequestHideAssetRequest} req hide asset request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public v1DashboardWalletsAssetsHidePost(req: RequestHideAssetRequest, options?: RawAxiosRequestConfig) {
        return AssetApiFp(this.configuration).v1DashboardWalletsAssetsHidePost(req, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BalanceApi - axios parameter creator
 * @export
 */
export const BalanceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieve the balance based on wallet ID.
         * @summary Get Balance
         * @param {number} id Wallet ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DashboardWalletsBalancesIdPost: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1DashboardWalletsBalancesIdPost', 'id', id)
            const localVarPath = `/v1/dashboard/wallets/balances/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BalanceApi - functional programming interface
 * @export
 */
export const BalanceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BalanceApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieve the balance based on wallet ID.
         * @summary Get Balance
         * @param {number} id Wallet ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DashboardWalletsBalancesIdPost(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpenapiRoutesBalanceOnWalletResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1DashboardWalletsBalancesIdPost(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BalanceApi.v1DashboardWalletsBalancesIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BalanceApi - factory interface
 * @export
 */
export const BalanceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BalanceApiFp(configuration)
    return {
        /**
         * Retrieve the balance based on wallet ID.
         * @summary Get Balance
         * @param {number} id Wallet ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DashboardWalletsBalancesIdPost(id: number, options?: RawAxiosRequestConfig): AxiosPromise<OpenapiRoutesBalanceOnWalletResponse> {
            return localVarFp.v1DashboardWalletsBalancesIdPost(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BalanceApi - object-oriented interface
 * @export
 * @class BalanceApi
 * @extends {BaseAPI}
 */
export class BalanceApi extends BaseAPI {
    /**
     * Retrieve the balance based on wallet ID.
     * @summary Get Balance
     * @param {number} id Wallet ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BalanceApi
     */
    public v1DashboardWalletsBalancesIdPost(id: number, options?: RawAxiosRequestConfig) {
        return BalanceApiFp(this.configuration).v1DashboardWalletsBalancesIdPost(id, options).then((request) => request(this.axios, this.basePath));
    }
}



