import { urls } from '../config';
import axiosRequest from './axios';

/**
 * @description - Get all notifications for a user identified with their Cognito subject (sub).
 */
export async function getNotifications() {
  return axiosRequest(urls.notificationsUrl);
}

/**
 * @description - Mark all notifications as seen.
 */
export async function markNotificationsAsSeen() {
  return axiosRequest(`${urls.notificationsUrl}/mark-all`);
}

/**
 * @description - Get count of all unread notifications for a user identified with their Cognito subject (sub).
 */
export async function unreadCount() {
  return axiosRequest(urls.notificationCount);
}
