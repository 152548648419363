type Urls = {
  baseUrl: string;
  apiUrl: string;
  superAdminUrl: string;
  adminUrl: string;
  transactionUrl: string;
  userUrl: string;
  userCurrent: string;
  userUpdate: string;
  userApiKey: string;
  notificationsUrl: string;
  notificationSettings: string;
  notificationCount: string;
  walletUrl: string;
  createWallet: string;
  walletAssets: string;
  walletsByOwner: string;
  walletById: string;
};

export enum enuEnvironment {
  local = 'local',
  uat = 'uat',
  production = 'production',
}

export const isMainnet = () => {
  return process.env.REACT_APP_ENVIRONMENT === enuEnvironment.production;
};

function baseUrlFromEnvironment(): string {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case enuEnvironment.local:
      return 'http://localhost:3030';
    case enuEnvironment.uat:
      return 'https://api.etanadigital.net';
    case enuEnvironment.production:
      return 'https://api.etanadigital.io';
    default:
      return 'https://api.etanadigital.net';
  }
}

function environmentUrls(): Urls {
  const baseUrl = baseUrlFromEnvironment();
  return {
    baseUrl,
    apiUrl: `${baseUrl}/v1/dashboard`,
    superAdminUrl: `${baseUrl}/v1/dashboard/superadmin`,
    adminUrl: `${baseUrl}/v1/dashboard/admin`,
    transactionUrl: `${baseUrl}/v1/dashboard/transactions`,
    userUrl: `${baseUrl}/v1/dashboard/user`,
    userUpdate: `${baseUrl}/v1/dashboard/user/update`,
    userCurrent: `${baseUrl}/v1/dashboard/user/current`,
    userApiKey: `${baseUrl}/v1/dashboard/user/apikey`,
    notificationsUrl: `${baseUrl}/v1/dashboard/notifications`,
    notificationSettings: `${baseUrl}/v1/dashboard/notifications/settings`,
    notificationCount: `${baseUrl}/v1/dashboard/notifications/count`,
    walletUrl: `${baseUrl}/v1/dashboard/wallets`,
    createWallet: `${baseUrl}/v1/dashboard/wallets/create`,
    walletAssets: `${baseUrl}/v1/dashboard/wallets/assets`,
    walletsByOwner: `${baseUrl}/v1/dashboard/wallets/owner`,
    walletById: `${baseUrl}/v1/dashboard/wallets/id`,
  };
}

export const urls = environmentUrls();

// TODO: deprecate
export const organizations: { id: string; name: string }[] = [
  { id: 'ETANATRUST', name: 'ETANA TRUST' },
  { id: 'BDACS', name: 'BDACS' },
];
