import { UserContextInterface } from '../../../../types/interfaces';
import { BaseUserSettings } from './BaseUserSettings';
import { NotificationSettings } from './NotificationSettings';

interface UserSettingsProps {
  userRef: UserContextInterface['userRef'];
}

const UserSettings = ({ userRef }: UserSettingsProps) => {
  return (
    <div className="grid justify-center space-y-10">
      <h1 className="text-2xl text-white">My Account</h1>
      <BaseUserSettings userRef={userRef} />
      {/*<NotificationSettings />*/}
    </div>
  );
};

export default UserSettings;
