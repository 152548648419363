import { useEffect, useState, useRef } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Toaster } from 'react-hot-toast';
import Cookies from 'universal-cookie';
import { Amplify, Hub } from 'aws-amplify';

// Components
import UserContext from './context/UserContext';

import Login from './components/authentication/Login';
import Authenticating from './components/authentication/Authenticating';
import UserSettings from './components/pages/profile/user/UserSettings';
import DashboardLayout from './components/layout/DashboardLayout';
import Home from './components/layout/Home';
import TransactionList from './components/pages/transactions/TransactionList';
import DepositList from './components/pages/deposits/DepositList';

import { TransactionCardFromParams } from './components/pages/transactions/TxCard';
import WalletList from './components/pages/wallets/WalletList';
import WalletPage from './components/pages/wallets/WalletPage';

import NewUser from './components/pages/organization/NewUser';
import OrgUsers from './components/pages/organization/OrganizationUsers';

import NewTransaction from './components/pages/wallets/new/NewTransaction';
import NewWallet from './components/pages/wallets/new/NewWallet';
import Modal from './components/layout/Modal';
import StakingList from './components/pages/staking/StakingList';
import Logout from './components/pages/logout/Logout';
import NotFound from './components/pages/NotFound';
import getSessionExpiration from './utils/getSessionExpiration';
import { storeUserSession } from './store/session';
import LoadingPage from './components/layout/LoadingPage';

import awsExports from './aws-exports';
import { toastOptions } from './config/toast';
import DepositCard from './components/pages/deposits/DepositCard';
import DeleteUser from './components/pages/organization/DeleteUser';
import { UsersServices } from './services/users.services';
import axiosRequest from './store/axios';
import { urls } from './config';

Amplify.configure(awsExports);

Hub.listen('auth', ({ payload }) => {
  switch (payload.event) {
    case 'signIn':
      const opts = { method: 'POST', data: payload };
      axiosRequest(`${urls.apiUrl}/event/auth/signin`, opts).catch(console.error);
      break;
    default:
      break;
  }
});

function App() {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const userRef = useRef({});

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [authCredentials, setAuthCredentials] = useState(null);
  const [authToken] = useState(cookies.get('jwt_token'));
  const [validCookie, setValidCookie] = useState(false);
  const [unprotectedRoutes] = useState(new Set(['/login', '/authenticating']));
  const [sessionTime, setSessionTime] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const userSession = useSelector(state => state.session.user);

  useEffect(() => {
    const token = cookies.get('jwt_token');
    if (token && !userSession) dispatch(storeUserSession());

    if (window.location.pathname === '/') navigate('/dashboard');

    UsersServices.getUser()
      .then(user => {
        const { groups, orgId, sub, givenName } = user;

        let role;
        if (groups) {
          if (groups.includes('superadmins')) role = 'superadmins';
          else if (groups.includes('admins')) role = 'admins';
          else role = 'investors';
        }

        setAuthCredentials(sub);
        userRef.current = { ...user, organization: orgId, role, name: givenName };
      })
      .then(async () => setSessionTime(await getSessionExpiration()))
      .catch(err => {
        if (unprotectedRoutes.has(window.location.pathname)) return;
        console.error(`Error with Authentication: ${err}`);
        navigate('/login');
      })
      .finally(() => setIsLoaded(true));

    if (!unprotectedRoutes.has(window.location.pathname)) {
      if (token) setValidCookie(true);
      else navigate('/login');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  return (
    <div className="min-h-screen h-full bg-gradient-to-b from-orange-950 to-black overflow-hidden font-montserratM">
      {isLoaded ? (
        <UserContext.Provider value={{ userRef }}>
          <Routes>
            {authCredentials || authToken || validCookie ? (
              <Route
                path="/dashboard"
                element={<DashboardLayout sessionTime={sessionTime} width={windowWidth} />}
              >
                <Route index element={<Home />} />
                <Route path="withdrawals">
                  <Route index element={<TransactionList />} />
                  <Route path=":id" element={<TransactionCardFromParams />} />
                </Route>
                <Route path="deposits">
                  <Route index element={<DepositList />} />
                  <Route path=":id" element={<DepositCard />} />
                </Route>
                <Route path="wallets">
                  <Route index element={<WalletList />} />
                  <Route path=":id" element={<WalletPage />} />
                  <Route path="new" element={<NewWallet />} />
                  <Route path=":id/create" element={<NewTransaction />} />
                </Route>
                <Route path="organization">
                  <Route index element={<OrgUsers userRef={userRef} />} />
                  <Route path="new" element={<NewUser />} />
                  <Route path="delete/:email" element={<DeleteUser />} />
                </Route>
                <Route path="staking">
                  <Route index element={<StakingList />} />
                </Route>
                <Route path="settings">
                  <Route index element={<UserSettings userRef={userRef} />} />
                </Route>
                <Route path="logout">
                  <Route index element={<Logout />} />
                </Route>
              </Route>
            ) : (
              <Route path="/login" element={<Login />} />
            )}
            <Route path="/login" element={<Login />} />
            <Route path="/authenticating" element={<Authenticating />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </UserContext.Provider>
      ) : (
        <LoadingPage />
      )}
      <Toaster toastOptions={toastOptions} />
      <Modal />
    </div>
  );
}

export default App;
