import { baseCard } from '../../../../config/tailwind.classnames';
import EmailVerification from '../EmailVerification';
import { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import { CopyToClipboard } from '../../../../assets/icons/icons';
import { ArrowPathIcon, InformationCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useDispatch } from 'react-redux';
import axiosRequest from '../../../../store/axios';
import { urls } from '../../../../config';
import { toast } from 'react-hot-toast';
import { currentComponent, hideModal, showModal } from '../../../../store/modal';
import capitalize from '../../../../utils/capitalize';
import { UsersServices } from '../../../../services/users.services';
import { AxiosResponse } from 'axios';
import { UserContextInterface } from '../../../../types/interfaces';

type UserData = {
  email: string;
  sub: string;
  givenName: string;
  familyName: string;
  webhookUrl: string;
  webhookKey: string;
  apiKey: string;
};

export function BaseUserSettings({ userRef }: { userRef: UserContextInterface['userRef'] }) {
  const isAdmin = userRef.current.groups.includes('admins');
  const [user, setUser] = useState<UserData | null>(null);
  const [editActive, setEditActive] = useState(false);

  const populateUser = () => {
    UsersServices.getUser()
      .then(user => setUser(user))
      .catch(err => {
        toast.error(`Failed to get Authenticated user\nStatus Code: ${err.status}`);
      });
  };

  useEffect(populateUser, []);

  const onSubmit = async () => {
    // Trim any leading or trailing whitespace
    const givenName = user.givenName?.trim();
    const familyName = user.familyName?.trim();
    const webhookUrl = user.webhookUrl?.trim();

    setUser(curr => ({
      ...curr,
      givenName,
      familyName,
      webhookUrl,
    }));

    const onStart = () => {
      toast.loading('Working...');
    };
    const onSuccess = () => {
      toast.dismiss();
      setEditActive(false);
    };
    const onError = (res: AxiosResponse) => {
      toast.error(`Failed to update user\nStatus Code: ${res.status}`);
    };
    const data = {
      email: user.email,
      update: {
        givenName,
        familyName,
        webhookUrl,
      },
    };
    UsersServices.updateUser({
      data,
      onStart,
      onSuccess,
      onError,
      userRef,
    });
  };

  return (
    <div className={`${baseCard} max-w-fit`}>
      {/*Header buttons*/}
      <span className="flex justify-end pb-4">
        <div className={`flex space-x-4 ${editActive ? '' : 'hidden'}`}>
          <button onClick={onSubmit} className="px-4 py-2 bg-green-700 hover:bg-green-600 rounded">
            <p className="text-sm font-medium leading-none text-white">Save Changes</p>
          </button>
          <button
            className="px-4 py-2 bg-gray-700 hover:bg-gray-600 rounded"
            onClick={() => {
              setEditActive(false);
              populateUser();
            }}
          >
            <p className="text-sm font-medium leading-none text-white">Cancel</p>
          </button>
        </div>
        <button
          className={`px-4 py-2 bg-green-700 hover:bg-green-600 rounded ${editActive ? 'hidden' : ''}`}
          onClick={() => setEditActive(true)}
        >
          <p className="text-sm font-medium leading-none text-white">Edit Info</p>
        </button>
      </span>

      <div className="flex">
        <div className="text-gray-400 min-w-40">Sub</div>
        <div>{user?.sub}</div>
      </div>
      <EditableField
        label="First Name"
        content={user?.givenName}
        placeholder={'Enter your first name'}
        edit={editActive && isAdmin}
        onChange={e => setUser(curr => ({ ...curr, givenName: e.target.value }))}
      />
      <EditableField
        label="Last Name"
        content={user?.familyName}
        placeholder={'Enter your last name'}
        edit={editActive && isAdmin}
        onChange={e => setUser(curr => ({ ...curr, familyName: e.target.value }))}
      />
      <div className="flex">
        <div className="text-gray-400 min-w-40">Email</div>
        <EmailVerification />
      </div>
      <EditableField
        label="Webhook URL"
        content={user?.webhookUrl}
        placeholder="Enter a URL to which HTTP requests may be sent"
        edit={editActive}
        onChange={e => setUser(curr => ({ ...curr, webhookUrl: e.target.value }))}
        informationOnHover={{
          title: 'Webhook URL',
          content:
            'Notifications about certain events may be sent as HTTP requests to a given URL.',
        }}
      />
      <div className="flex items-center">
        <InformationOnHover
          title="Webhook Key"
          content="Use this 32-byte key to decode webhooks with AES."
        />
        <HiddenValue label="Webhook key" value={user?.webhookKey} />
      </div>
      <div className="flex items-center">
        <InformationOnHover
          title="API Key"
          content={
            <div>
              <span>
                To use the Etana Digital API, include this key as a header in each HTTP request:
              </span>
              <span className="flex justify-center">
                <code className="bg-gray-300 px-1 py-0.5 max-w-fit">X-API-Key: [API KEY]</code>
              </span>
            </div>
          }
        />
        <HiddenValue label="API key" value={user?.apiKey} />
        <div className="flex pl-5 space-x-2">
          <ApiKeyAction
            action="rotate"
            onChange={data => setUser(curr => ({ ...curr, apiKey: data.apiKey }))}
          />
          <ApiKeyAction
            action="delete"
            onChange={data => setUser(curr => ({ ...curr, apiKey: data.apiKey }))}
          />
        </div>
      </div>
    </div>
  );
}

type EditableFieldProps = {
  label: string;
  content?: string;
  placeholder: string;
  edit: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  informationOnHover?: InformationOnHoverParams;
};

function EditableField({
  label,
  content,
  placeholder,
  edit,
  onChange,
  informationOnHover,
}: EditableFieldProps) {
  const inner = edit ? (
    <input
      type="text"
      value={content}
      autoComplete="off"
      data-lpignore="true"
      placeholder={placeholder}
      onChange={onChange}
      className="w-96 text-sm rounded p-1 bg-gray-800 bg-opacity-70 text-right"
    />
  ) : (
    <div>{content || <span className="opacity-60">[none]</span>}</div>
  );
  const title = Boolean(informationOnHover) ? (
    <InformationOnHover title={informationOnHover.title} content={informationOnHover.content} />
  ) : (
    <div className="text-gray-400 min-w-40">{label}</div>
  );
  return (
    <div className="flex">
      {title}
      {inner}
    </div>
  );
}

type HiddenValueParams = { label: string; value: string };

function HiddenValue({ label, value }: HiddenValueParams) {
  const [showValue, setShowValue] = useState(false);
  const shown = showValue ? value || '[none]' : '*'.repeat(30);
  return (
    <div className="flex text-xs text-gray-400 space-x-4">
      <span className="md:min-w-64">{shown}</span>
      <span className="flex space-x-1">
        <CopyToClipboard label={label} text={value} />
        <div
          onClick={() => setShowValue(cur => !cur)}
          className="text-gray-400 hover:text-blue-300 cursor-pointer text-xs"
        >
          {showValue ? 'Hide' : 'Show'}
        </div>
      </span>
    </div>
  );
}

type InformationOnHoverParams = { title: string; content: string | ReactNode };

function InformationOnHover({ title, content }: InformationOnHoverParams) {
  const [hover, setHover] = useState(false);
  return (
    <div className="flex items-center space-x-2 text-gray-400 min-w-40">
      <span>{title}</span>
      <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
        <InformationCircleIcon className="w-5 text-blue-500 opacity-70" />
        <div
          className={
            hover ? 'z-10 absolute bg-white text-black text-xs p-2 rounded shadow-md' : 'hidden'
          }
        >
          {content}
        </div>
      </div>
    </div>
  );
}

type ApiKeyActionProps = {
  action: 'rotate' | 'delete';
  onChange: (data: any) => void;
};

function ApiKeyAction({ action, onChange }: ApiKeyActionProps) {
  const dispatch = useDispatch();

  function onContinue() {
    const query = action === 'delete' ? '?delete=true' : '';
    axiosRequest(urls.userApiKey + query)
      .then(res => onChange(res.data.data))
      .then(() => toast.success(`Successfully ${action}d API Key`))
      .catch(e => toast.error(`Failed to ${action} API Key: ${e}`));
    dispatch(hideModal());
  }

  function onCancel() {
    dispatch(hideModal());
  }

  function onClick() {
    dispatch(
      currentComponent(() => (
        <ApiKeyActionAreYouSure action={action} onContinue={onContinue} onCancel={onCancel} />
      )),
    );
    dispatch(showModal());
  }

  const rotate = action === 'rotate';
  return (
    <button
      className={`flex items-center space-x-1 text-xs p-1 rounded-md hover:opacity-90 ${rotate ? 'bg-green-700' : 'bg-red-700'}`}
      onClick={onClick}
    >
      {rotate ? <ArrowPathIcon className="w-4" /> : <TrashIcon className="w-4" />}
      <span>{rotate ? 'Rotate' : 'Delete'}</span>
    </button>
  );
}

type ApiKeyActionAreYouSureProps = {
  action: 'rotate' | 'delete';
  onContinue: () => void;
  onCancel: () => void;
};

function ApiKeyActionAreYouSure({ action, onContinue, onCancel }: ApiKeyActionAreYouSureProps) {
  return (
    <div className="text-slate-900 bg-amber-100 p-10 rounded-md max-w-xl min-w-fit space-y-5">
      <h2 className="text-xl font-semibold">Are you sure?</h2>
      <p className="text-red-700">This action cannot be undone.</p>
      <p>
        If you have any services using this API key, they will no longer be able to access the Etana
        Digital API.
      </p>
      <span className="flex justify-between space-x-4">
        <button
          className="p-2 flex flex-1 justify-center items-center rounded-md text-white bg-green-700 shadow shadow-slate-900 hover:bg-green-800"
          onClick={onContinue}
        >
          {capitalize(action)}
        </button>
        <button
          className="p-2 flex flex-1 justify-center items-center rounded-md text-white bg-gray-700 shadow shadow-slate-900 hover:bg-gray-800"
          onClick={onCancel}
        >
          Cancel
        </button>
      </span>
    </div>
  );
}
