import axiosRequest from '../store/axios';
import { User, UserContextInterface } from '../types/interfaces';
import { AxiosResponse } from 'axios';
import { urls } from '../config';

export type UserUpdateRequest = {
  email: string;
  update: Partial<User>;
};

export type UpdateUserParams = {
  data: UserUpdateRequest;
  onStart: () => void;
  onSuccess: () => void;
  onError: (res: AxiosResponse) => void;
  userRef: UserContextInterface['userRef'];
};

export class UsersServices {
  static updateUser(ps: UpdateUserParams) {
    axiosRequest(urls.userUpdate, { method: 'POST', data: ps.data }).then(res => {
      ps.onStart();
      if (res.status >= 300) {
        ps.onError(res);
        return;
      }
      if (ps.userRef.current.email === ps.data.email) {
        ps.userRef.current = Object.assign(ps.userRef.current, ps.data);
      }
      ps.onSuccess();
    });
  }

  static async getUser(): Promise<User> {
    return axiosRequest(urls.userCurrent, { method: 'GET' }).then(res => res.data);
  }
}
